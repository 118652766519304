import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import config from 'babel-app/config/environment';

export default class AdminController extends Controller {
  @service session;
  @service router;
  
  get showBetaBanner() {
    return this.router.currentRouteName === 'master.admin.teachers.index' ||
      this.router.currentRouteName === 'master.admin.students.index';
  }

  get getEnvironment() {
    if (config.endpoint.includes('dev')) {
      return 'dev';
    }

    if (config.endpoint.includes('stage')) {
      return 'stage';
    }

    return 'prod';
  }
}
